/* PLAYER PROFILE STYLES */

/* PLAYER HEADER */
.playerHeader {
  @apply relative h-[226px] md:h-[320px];
  isolation: isolate;
  /* background: radial-gradient(522.93% 99.78% at 23.54% 57.62%, rgba(0, 0, 0, 0.00) 23.5%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.12) 0%, rgba(0, 0, 0, 0.12) 100%), linear-gradient(0deg, var(--playerColor) 0%, var(--playerColor) 100%), #FFF; */
}

.playerHeaderBtn {
  @apply w-fit bg-white absolute top-4 left-4 rounded-full px-1.5 z-[3] md:px-3 py-0 h-6 md:h-10 text-label-medium md:text-title-sm-medium gap-1 md:gap-2;
  text-transform: initial;
  right: 0;

  img {
    @apply w-6 h-6 md:w-10 md:h-10;
  }

  > div {
    @apply gap-2;
  }

  svg {
    @apply ml-2;
  }

  @media (max-width: 1232px) {
    @apply right-6;
  }

  @media (max-width: 767px) {
    @apply top-4;
  }
}

.playerHeaderTitle {
  @apply font-bold uppercase text-white md:mb-1 leading-none text-headline-lg md:text-[4rem] tracking-[-0.24px] md:tracking-[-0.04rem];
}

.playerHeaderPosition {
  @apply text-body md:text-headline uppercase text-white;
}

.playerHeaderNumber {
  @apply relative uppercase text-white font-bold text-[5rem] md:text-[13.5rem] leading-none tracking-[-4px] md:tracking-[-0.87rem];
}
.teamHeaderLogoLeft{
    @apply absolute flex justify-center items-center top-2/4;
    width: 692px;
    height: 692px;
    left: -486px;
    transform: translateY(-51%);
    z-index: -1;
    opacity: 0.1;

    @media (max-width: 767px) {
      width: 334px;
      height: 334px;
      left: -15%;
    }
  }
.gradientText {
  display: flex;
  line-height: 0.8;
  letter-spacing: -1%;
  background: -webkit-linear-gradient(45deg, #fff, rgba(255,255,255,0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.playerHeaderDisplayLarge {
  @apply absolute flex justify-center items-center top-2/4 w-[254px] md:w-[892px]  left-[-100px] md:left-[-185px];
  transform: translateY(-47.5%);
  z-index: -1;

  @media (max-width: 767px) {
    transform: translateY(-46%);
  }

  img {
    @apply absolute w-full h-full object-fill max-w-none pointer-events-none top-0 left-0 opacity-10;

    + div {
      @apply absolute flex flex-col md:items-end left-[108%] md:left-[85.5%] top-[23%] md:top-[32.5%] gap-0;
    }
  }

  span {
    @apply uppercase font-bold text-white text-display-xl md:text-[138px] leading-[0.77] tracking-[0] md:tracking-[-1.377px];
    opacity: 0.2;
    background: linear-gradient(149deg, #FFF -60.23%, rgba(255, 255, 255, 0.00) 81.16%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:first-child {
      @apply md:mr-[57px];
    }
  }

}

.playerHeaderWrap{
  @apply flex gap-3 md:gap-8 lg:gap-14 items-center pt-12 md:pt-0 ;
}

.playerHeaderHeadshot{
  @apply absolute w-[200px] h-[200px] md:w-[520px] md:h-[520px] rounded-xl left-[-14px] md:left-[-7.5rem] top-[35%] md:top-auto bottom-auto md:bottom-[-174px];
  z-index: 1;

  &:after {
    content: '';
    @apply absolute w-full h-full;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(244, 244, 246, 0) 72.21%, rgb(244 244 246) 100%);
  }
}

.playerHeaderDisplaySmall {
  > div {
    @apply gap-1 md:gap-2;
  }

  img {
    @apply block w-[36px] h-[36px] md:w-[72px] md:h-[72px] object-fill;

    + div {
      @apply gap-1 md:gap-2;
    }
  }

  span {
    @apply text-title-sm-medium md:text-headline-lg tracking-[0] md:tracking-[-0.24px] uppercase md:font-bold text-white;
  }

}

.overlay {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  mix-blend-mode: luminosity;
  background: #292929;

}
.triangle {
  composes: overlay;
  clip-path: polygon(312px 0, 100% 0, 100% 100%, 0% 100%);
}

.playerHeaderOverlay{
  @apply absolute block opacity-20 top-0 bottom-0 md:top-0 md:bottom-0 -ml-[135px] md:-ml-[330px] left-[100%] z-[1];
  background: #292929;
  right: calc(-50vw + 616px);
  mix-blend-mode: luminosity;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  clip-path: polygon(312px 0, 100% 0, 100% 100%, 0% 100%);

  @media (max-width: 1232px) {
    @apply right-0;
  }

  @media (max-width: 767px) {
    left: 70%;
    clip-path: polygon(212px 0, 100% 0, 100% 100%, 0% 100%);
  }
}

/* PLAYER DATA BOX */
.playerDataGrid{
  @apply relative grid items-start grid-cols-2 lg:grid-cols-4 gap-x-3 gap-y-2 md:gap-4 max-w-[800px] md:ml-[228px] z-[1] mt-[26px] md:-mt-[3.5rem];
}
.playerDataBox{
  @apply flex flex-1 min-h-[93px] md:min-h-0 flex-col justify-between md:justify-start gap-2 uppercase bg-white p-4 md:p-6 rounded-lg md:rounded-xl;
  background: rgba(255, 255, 255, 0.81);
  backdrop-filter: blur(14px);
}
.playerDataBoxTitle{
  @apply flex text-display-xl !text-[2rem] !leading-[2rem] md:!text-[3rem] md:!leading-[2.5rem] items-baseline;
}