.card {
  perspective: 1000px;
}

.cardInner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 2.0s;
  transform-style: preserve-3d;
}

.cardInner.flipped {
    animation: flip 2s forwards ease-in-out;
}

.cardInner.flipped .cardContent {
  animation: fadeInSlideUp 1s ease-in-out 1.25s forwards;
}

@keyframes flip {
  0% {
    transform: perspective(1000px) rotateX(0deg);
  }
  100% {
    transform: perspective(1000px) rotateX(360deg);
  }
}

@keyframes fadeInSlideUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shimmer {
  0% {
    left: -300%;
  }
  100% {
    left: 300%;
  }
}

.cardFront,
.cardBack, .cardContent {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.cardContent {
  transition: all 1s ease-in-out;
}

.cardBack {
  background-color: var(--secondary-300);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotateX(180deg);
  overflow: hidden;
}

.cardInner.flipped .cardBack::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -300%;
  height: 300%;
  width: 300%;
  background: linear-gradient(135deg, transparent 25%, var(--primary) 50%, transparent 75%);
  /* animation: shimmer 2.0s cubic-bezier(0.2, 0.8, 0.8, 1) 0.75s forwards; */
  will-change: left;
}
