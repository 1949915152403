.root {
  @apply flex text-center justify-center items-center rounded-full text-label-sm-bold h-5 px-2 capitalize;
  min-width: 2.875rem;
}
.root[data-status="win"] {
    @apply bg-success-700 text-success-300;
}
.root[data-status="loss"] {
    @apply bg-error-700 text-error-300;
}
.root[data-status="push"] {
    @apply bg-warning-700 text-warning-300;
}
.root[data-status="win"][data-dark="true"] {
    @apply bg-success-300;
}
.root[data-status="loss"][data-dark="true"] {
    @apply bg-error-300;
}
.root[data-status="push"][data-dark="true"] {
    @apply bg-warning-300;
}
.root[data-status="Delayed"][data-dark="true"] {
    @apply bg-surface-700 text-secondary-300;
}
.root[data-status="Postponed"][data-dark="true"] {
    @apply bg-surface-700 text-secondary-300;
}
.root[data-status="Canceled"][data-dark="true"] {
    @apply bg-surface-700 text-secondary-300;
}
.root[data-dark="true"] {
    @apply text-white;
}
