.root {
  @apply rounded-xl p-4 desktop-grid:p-6 overflow-hidden relative desktop-grid:min-h-[135px] min-w-[130px] max-h-[106px] desktop-grid:max-h-[135px] z-0 bg-black-gradient;
  --scorching-gradient: linear-gradient(180deg, #ff0016 50%, #f90 100%);
  --hot-gradient: linear-gradient(180deg, #ff7a00 53.88%, #ffc700 100%);
  --warm-gradient: linear-gradient(180deg, #ffb800 53.88%, #faff00 150%);
  --green-gradient: linear-gradient(180deg, #CFE549 53.88%, #AEE549 150%);
  --cold-gradient: linear-gradient(180deg, #557aff 0%, #875eff 100%);
  --freezing-gradient: linear-gradient(92deg, #5ea8ff 12.28%, #55e0ff 78.05%);
  --shadow-white: 0px 0px 2px 0px #fff, 0px 0px 3px 0px #fff;
  --shadow-moneyline: 0px 0px 2px 0px #93e100, 0px 0px 3px 0px #93e100;
  --shadow-hot: 0px 0px 2px 0px #f5b104, 0px 0px 3px 0px #f5b104;
  --shadow-freezing: 0px 0px 2px 0px #56dfff, 0px 0px 3px 0px #56dfff;
  & .bg,
  & .bgTeam {
    @apply absolute inset-0 w-full h-full -z-[1] bg-no-repeat bg-right-bottom bg-auto;
  }
  & .bg {
    @apply bg-contain;
  }
  & .bgTeam {
    @apply flex justify-end items-start opacity-10;
    & > img {
      @apply w-3/5;
    }
  }
  & .line {
    @apply w-6 h-px mix-blend-screen;
    background: #aee449;
    box-shadow: var(--shadow-moneyline);
  }
  & .trim {
    @apply absolute -top-[7px] left-0 -right-[42px] h-[13px] bg-none;
  }
  & .numbers {
    @apply text-display-lg desktop-grid:text-[3rem] md:font-bold uppercase md:leading-10 bg-clip-text text-transparent;
    -webkit-background-clip: text;
  }
  &[data-rarity="none"] {
    & .numbers {
      @apply text-primary-500;
    }
  }
  &[data-rarity="freezing"] {
    & .bg {
      background-size: 77px;
      background-image: url("/images/bettor/metrics/record-freezing.png");
    }
    & .line {
      background: white;
      box-shadow: var(--shadow-freezing);
    }
    & .trim {
      background: var(--freezing-gradient);
    }
    & .numbers {
      background: var(--freezing-gradient);
      -webkit-background-clip: text;
    }
  }
  &[data-rarity="cold"] {
    & .bg {
      background-size: 61px;
      background-image: url("/images/bettor/metrics/record-cold.png");
    }
    & .line {
      background: white;
      box-shadow: var(--shadow-white);
    }
    & .numbers {
      background: var(--cold-gradient);
      -webkit-background-clip: text;
    }
  }
  &[data-rarity="warm"] {
    & .line {
      background: white;
      box-shadow: var(--shadow-white);
    }
    & .trim {
      background: var(--warm-gradient);
    }
    & .numbers {
      background: var(--warm-gradient);
      -webkit-background-clip: text;
    }
  }
  &[data-rarity="common"] {
    & .line {
      background: white;
      box-shadow: var(--shadow-white);
    }
    & .trim {
      background: var(--green-gradient);
    }
    & .numbers {
      background: var(--green-gradient);
      -webkit-background-clip: text;
    }
  }
  &[data-rarity="hot"] {
    & .bg {
      background-size: 57px;
      background-image: url("/images/bettor/metrics/record-hot.png");
    }
    & .line {
      background: white;
      box-shadow: var(--shadow-hot);
    }
    & .trim {
      background: var(--hot-gradient);
    }
    & .numbers {
      background: var(--hot-gradient);
      -webkit-background-clip: text;
    }
  }
  &[data-rarity="scorching"] {
    & .bg {
      background-size: 98px;
      background-image: url("/images/bettor/metrics/record-scorching.png");
    }
    & .line {
      background: white;
      box-shadow: var(--shadow-white);
    }
    & .trim {
      background: var(--scorching-gradient);
    }
    & .numbers {
      background: var(--scorching-gradient);
      -webkit-background-clip: text;
    }
  }
}

.numbers {
  @apply mt-1 whitespace-nowrap;
}
