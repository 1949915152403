@keyframes pulse {
  100% {
    box-shadow: 0 0 0 0.8rem transparent;
  }
}
.root {
  @apply inline-flex items-center;
  transition: all 300ms ease;
  &:disabled {
    @apply cursor-pointer pointer-events-none;
  }
  &:not(:active) {
    animation: pulse 888ms;
  }
  &:active {
   @apply scale-90; 
  }
  &.default {
    &.standard {
      @apply justify-center whitespace-nowrap text-secondary-300 px-5 py-3 rounded-md;
      &.xs {
        @apply text-label-bold uppercase p-3 h-8 gap-0.5;
      }
      &.sm {
        @apply text-title-sm-bold uppercase px-4 py-3 h-9 gap-2;
      }
      &.md {
        @apply text-title-lg-bold uppercase px-4 py-5 h-11 gap-2;
      }
    }
    &.circle {
      @apply justify-center rounded-full;
      &.xs {
        @apply p-2;
      }
      &.sm {
        @apply p-3;
      }
      &.md {
        @apply p-4;
      }
    }
    &.primary {
      @apply bg-primary-500 text-secondary-300;
      box-shadow: 0 0 0 0 var(--primary-500);
      &:hover {
        @apply bg-primary-600;
      }
      &:active {
        @apply bg-primary-400;
      }
    }
    &.secondary {
      @apply bg-surface-700 text-secondary-300 fill-secondary-300;
      box-shadow: 0 0 0 0 var(--primary-500);
      &:hover {
        @apply bg-primary-500;
      }
      &:active {
        @apply bg-primary-400;
      }
    }
    &.tertiary {
      @apply bg-surface-600 text-on-tertiary fill-on-tertiary;
      box-shadow: 0 0 0 0 var(--surface-500);
      &:hover {
        @apply bg-surface-500;
      }
      &:active {
        @apply bg-secondary-700;
      }
    }
    &.light {
      @apply bg-surface-700 text-secondary-300 fill-secondary-300;
      &:hover {
        @apply bg-surface-600;
      }
      &:active {
        @apply bg-primary-500;
      }
    }
    &.dark {
      @apply bg-secondary-300 text-surface-700 fill-surface-700;
      &:hover {
        @apply bg-secondary-400;
      }
      &:active {
        @apply bg-secondary-200;
      }
    }
    &.success {
      @apply bg-success text-on-success fill-surface;
      &:hover {
        @apply bg-success-600;
      }
      &:active {
        @apply bg-success-400;
      }
    }
    &.error {
      @apply bg-error text-on-error fill-surface;
      &:hover {
        @apply bg-error-600;
      }
      &:active {
        @apply bg-error-400;
      }
    }
  }
  &.underlined {
    @apply inline-flex text-base normal-case underline text-primary-200 decoration-transparent;
    &:hover {
      @apply decoration-primary-200;
    }
  }
  &.card {
    @apply justify-center rounded-md text-label-bold bg-transparent text-secondary uppercase py-2 px-2 gap-2 outline-1;
    &:hover {
      @apply bg-surface-600;
    }
  }
  &.info {
    @apply text-label-lg-bold uppercase hover:bg-white/40 py-1 px-1 bg-transparent rounded-md;
  }
  &.sport {
    @apply text-title-sm-medium uppercase rounded-sm gap-2 py-1 pl-1 pr-2 outline-none bg-dark text-white fill-primary h-[26px];
    box-shadow: 0 0 0 0 var(--primary-500);
    &:hover {
      @apply scale-105;
    }
    &:active svg {
      @apply scale-95;
    }
  }
  &.tile {
    @apply h-full relative uppercase text-title-medium;
    &:after {
      @apply -right-[2px] bg-primary-500 absolute -bottom-[2px] left-0 h-[4px] rounded-[4px_4px_0px_0px] scale-y-0 opacity-0 transition-all origin-bottom;
    }
    &:hover {
      &:after {
        @apply scale-y-100 opacity-100;
      }
    }
    &[data-state="active"] {
      @apply text-title-bold;
      &:after {
        @apply scale-y-100 opacity-100;
      }
    }
  }
}