.root {
    @apply flex w-[5.0625rem] h-9 relative z-0 overflow-hidden gap-1 items-center rounded-md text-title-bold p-2 justify-center disabled:pointer-events-none disabled:!bg-transparent;
    .payout {
        @apply text-grey text-label-lg-bold;
    }
    &[data-color="default"] {
        @apply bg-surface-600 active:bg-primary-400 text-secondary-300 hover:bg-primary-500;
    }
    &[data-disabled="true"] {
        @apply pointer-events-none !bg-transparent text-title;
    }
    &[data-loss="true"] {
        @apply text-secondary-400 bg-transparent;
    }
    &[data-color="light"] {
        @apply bg-surface-700 active:bg-primary-400 text-secondary-300 hover:bg-primary-500;
    }
    &[data-color="neutral"] {
        @apply bg-surface-700 desktop-grid:bg-surface-500 active:bg-primary-400 text-secondary-300 hover:bg-primary-500;
    }
    &[data-color="dark"] {
        @apply bg-[#3A3C3F] text-white hover:bg-primary-500 hover:text-secondary-300 active:bg-primary-400;
        .payout {
            @apply text-secondary;
        }
        &[data-loss="true"] {
            @apply text-secondary-600;
        }
        &[data-added="true"] {
            .payout {
                @apply text-secondary-300;
            }
        }
    }
    &[data-added="true"] {
        @apply text-secondary-300 bg-primary-500;
        .payout {
            @apply text-secondary-300;
        }
    }
}
.root:hover {
    &[data-color="dark"] {
        .payout {
            @apply text-secondary-200;
        }
    }
    .payout {
        @apply text-secondary-200;
    }
}
