.table.md {
    & :global(th), & :global(td) {
        @apply h-[48px];
    }
}
.table.sm {
    & :global(th), & :global(td) {
        @apply h-[32px];
    }
}
.table.final {
    @apply bg-surface-300 rounded-md;
    & :global(th), & :global(td) {
        @apply bg-surface-300;
    }
    .greyHeader1, .greyHeader2, .greyHeader3, .greyHeader4, .greyCell1, .greyCell2, .greyCell3, .greyCell4 {
        @apply bg-surface-700;
    }
    & :global(tr:not(:last-child)) > .greyCell4 {
        @apply bg-surface-700;
    } 
    & :global(tr:last-child) > .greyCell1, & :global(tr:last-child) > .greyCell3, & :global(tr:last-child) > .greyCell4 {
        @apply bg-surface-700;
    }
    .greyHeader1, .greyHeader2, .greyHeader3, .greyHeader4 {
        @apply border-secondary-700;
    }
    & :global(th) {
        @apply border-b border-b-secondary-700 h-8;
    }
    .greyCell1 {
        @apply border-r border-r-secondary-700;
    }
    .greyCell3 {
        @apply border-l border-l-secondary-700;
    }
    & :global(tr:first-child) > :global(th:first-child) {
        @apply rounded-tl-lg;
    }
    & :global(tr:last-child) > :global(td:first-child) {
        @apply rounded-bl-lg;
    }
}
.table {
    table-layout: fixed;
    & :global(th), & :global(td) {
    @apply relative p-0 w-full text-center justify-center text-label-lg-medium uppercase text-secondary min-w-[46px] desktop-grid:min-w-[48px];
        &.past {
            @apply text-dark;
        }
        &.disabled {
            @apply text-secondary-500;
        }
    }
    /* DEFAULT */
    & :global(tr) > .greyHeader4 {
        @apply rounded-tl-sm rounded-tr-sm bg-surface-500 text-dark;
    } 
    & :global(tr:not(:last-child)) > .greyCell4 {
        @apply bg-surface-500 text-dark;
    } 
    & :global(tr:last-child) > .greyCell4 {
        @apply rounded-bl-sm rounded-br-sm bg-surface-500 text-dark;
    }
    .liveHeader {
        @apply bg-primary-500 rounded-tl-sm rounded-tr-sm max-w-[20px];
    }
    /* MLB */
    .greyHeader1 {
        @apply rounded-tl-sm bg-surface-500 text-dark border-b border-r border-white;
    } 
    .greyHeader2 {
        @apply bg-surface-500 text-dark border-b border-white;
    } 
    .greyHeader3 {
        @apply rounded-tr-sm bg-surface-500 text-dark border-b border-l border-white;
    } 
    .greyCell1, .greyCell2, .greyCell3 {
        @apply bg-surface-500 text-secondary-300;
    }
    .greyCell1 {
        @apply border-r border-r-white;
    }
    .greyCell3 {
        @apply border-l border-l-white;
    }
    & :global(tr:last-child) > .greyCell1 {
        @apply rounded-bl-sm bg-surface-500 text-dark;
    } 
    & :global(tr:last-child) > .greyCell3 {
        @apply rounded-br-sm bg-surface-500 text-dark;
    } 
    & :global(th) {
        @apply border-b border-b-surface-400 h-8;
    }
    & :global(th:last-child) {
        @apply border-b-white;
    }
    .atBat {
        @apply p-0.5;
        & > div {
            @apply bg-surface-500 rounded-sm flex items-center justify-center h-full;
        }
    }
}