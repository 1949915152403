.root {
  &[data-variant="odds"],
  &[data-variant="picks"],
  &[data-variant="sport"],
  &[data-variant="news"],
  &[data-variant="sportsbook"],
  &[data-variant="leaderboard"],
  &[data-variant="settings-mobile"],
  &[data-variant="dashboard-mobile"],
  &[data-variant="bettor-mobile"] {
    @apply pb-10;
    .outer {
      @apply fixed top-[(--navbar-height)] bg-white w-full shadow-elevation-300 z-[49] h-10 max-[640px]:overflow-x-auto overflow-y-hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .inner {
      @apply max-w-[77rem] mx-auto px-4 flex items-center justify-start gap-4 h-full overflow-x-auto overflow-y-hidden max-[640px]:w-fit;
    }
    .splide {
      @apply h-full;
    }
    /* .splide :global(.splide__list) {
      @apply !h-auto;
    } */
    .nav {
      @apply overflow-hidden;
      &::-webkit-scrollbar {
        @apply h-0;
      }
      @apply h-full w-full;
    }
    .list {
      @apply h-10;
    }
    .button {
      /* @apply mr-6; */
    }
  }
  &[data-variant="bettor"] {
    @apply pb-0;
    .outer {
      @apply static desktop-grid:rounded-lg bg-white w-full shadow-[none] px-2 desktop-grid:px-0 h-[3.75rem] max-[640px]:overflow-x-auto overflow-y-hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .inner {
      @apply max-w-[77rem] mx-auto px-4 flex items-center justify-start gap-4 h-full overflow-y-hidden max-[640px]:w-fit;
    }
    .splide {
      @apply h-full max-[640px]:flex max-[640px]:items-center;
    }
    .nav {
      /* @apply overflow-x-auto overflow-y-hidden; */
      @apply h-full w-full;
    }
    .list {
      @apply h-[3.75rem];
    }
    .button {
      @apply text-secondary-300 font-bold;
    }
  }
  &[data-variant="team"] {
    --teamColor: var(--blue);
    @apply pb-2;
    .outer {
      @apply static shadow-[none] bg-white h-[3.75rem] max-[640px]:overflow-x-auto overflow-y-hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .splide {
      @apply h-full;
    }
    .inner {
      @apply max-w-[77rem] mx-auto px-4 flex items-center justify-start gap-4 h-full overflow-x-auto overflow-y-hidden max-[640px]:w-fit;
    }
    .nav {
      /* @apply overflow-x-auto overflow-y-hidden; */
      @apply h-full;
    }
    .list {
      @apply h-[3.75rem];
    }
    .button {
      @apply after:bg-[var(--teamColor)] font-bold;
    }
  }
  &[data-variant="betting-guide"] {
    @apply bg-secondary-100 rounded-tl-lg rounded-tr-lg pb-0 max-w-[360px];
    .outer {
      @apply static bg-transparent h-[3.75rem] max-[640px]:overflow-x-auto overflow-y-hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .inner {
      @apply max-w-[77rem] mx-auto px-4 flex items-center justify-start gap-4 h-full overflow-x-auto overflow-y-hidden max-[640px]:w-fit;
    }
    .splide {
      @apply h-full;
    }
    /* .nav {
      @apply h-full;
    } */
    .list {
      @apply h-[3.75rem];
    }
    .button {
      @apply text-white font-bold mr-6;
    }
  }
  &[data-variant="sportsbetting-101"] {
    @apply bg-white rounded-bl-lg rounded-br-lg mb-8;
    .outer {
      @apply static bg-transparent h-[3.75rem] max-[640px]:overflow-x-auto overflow-y-hidden;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .inner {
      @apply max-w-[77rem] mx-auto px-4 flex items-center justify-start gap-4 h-full overflow-x-auto overflow-y-hidden max-[640px]:w-fit;
    }
    .splide {
      @apply h-full;
    }
    .nav {
      @apply h-full w-full;
    }
    .list {
      /* @apply h-[3.75rem]; */
    }
    .button {
      @apply text-secondary-300 font-bold;
    }
  }
  &[data-variant="settings"],
  &[data-variant="dashboard"] {
    .list {
      @apply flex-col justify-center;
      & > :global(li) {
        @apply w-full;
      }
    }
    .button {
      @apply w-full normal-case text-title-sm-medium text-white min-w-[12.5rem] justify-start h-12 px-4 py-2 bg-transparent text-secondary-300 fill-secondary-500;
      &[data-state="active"] {
        @apply bg-secondary-300 fill-primary text-white;
      }
      &:not([data-state="active"]):hover {
        @apply bg-white;
      }
    }
  }

  &[data-hide="mobile"] {
    @apply max-md:hidden;
  }
  &[data-hide="desktop"] {
    @apply md:hidden;
  }
}
