.table {
  @apply overflow-x-auto overflow-y-hidden;
  & > :global(table > tr > td) {
    @apply pr-2 py-1.5 pl-0;
  }
  & > :global(table > tr > td:not(:last-child)) {
    @apply pr-4 py-1.5 pl-0 w-0;
  }
  & > :global(table > tr > td:first-child) {
    @apply w-14;
  }
}

.tableGroup {
  @apply flex flex-col gap-3;
  &:not(:last-child) {
    @apply border-b-surface-600 border-b flex flex-col gap-1 pb-3;
  }
}

.chartGrid {
  --offset: -1rem;
  --divider-width: 2px;
  --length: 90%;
  @apply grid grid-cols-1 min-[386px]:grid-cols-2 grid-rows-2 gap-x-4 gap-y-6 overflow-hidden;
  & > div {
    position: relative;
  }
  & > div:before,
  & > div:after {
    @apply bg-surface-600 content-[''] absolute;
  }
  & > div:before {
    width: var(--divider-width);
    height: var(--length);
    top: 0;
    left: var(--offset);
  }
  & > div:after {
    width: var(--length);
    height: var(--divider-width);
    top: var(--offset);
    left: 0;
  }
}
