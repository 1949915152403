.root {
    @apply rounded-sm gap-2 flex justify-center items-center py-1 px-2 capitalize text-body-sm whitespace-nowrap;
    &[data-status="default"] {
        @apply text-secondary-300 bg-surface-600;
    }
    &[data-status="success"] {
        @apply text-success-300 bg-success-700;
    }
    &[data-status="error"] {
        @apply text-error-300 bg-error-700;
    }
}